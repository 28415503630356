import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useThemeUI } from "@theme-ui/core";

import GridRow from "../../commons/GridRow";
import Heading from "../../commons/Heading";
import ArticleThumbnail from "../../commons/ArticleThumbnail";
import SeeMoreGrid from "../../organisms/SeeMoreGrid";

const tags = {
  article: "Blog",
  caseStudy: "Case Study",
};

export default function CaseStudiesBlock({
  title,
  caseStudies,
  ...otherProps
}) {
  const { theme } = useThemeUI();
  const data = useStaticQuery(query);

  return (
    <GridRow as="section" sx={{ py: [5, "gridGap"] }}>
      <GridRow.Col
        gridColumn={["start / end", "start / span 6"]}
        sx={{ mb: [4, 0] }}
      >
        <Heading as="h4" variant="h2">
          {title}
        </Heading>
      </GridRow.Col>
      <GridRow.Col>
        <SeeMoreGrid>
          {caseStudies.map((node) => {
            return (
              <ArticleThumbnail
                key={node._type}
                to={`/${data[node._type].slug.current}/${node.slug.current}`}
                title={node.title}
                media={node.heroImage}
                tag={node.resourceType?.title || tags[node._type]}
                sizes={`(max-width: ${theme.breakpoints.minSize}) 100vw, 500px`}
              />
            );
          })}
        </SeeMoreGrid>
      </GridRow.Col>
    </GridRow>
  );
}

const query = graphql`
  query CaseStudiesBlock {
    caseStudy: sanityPage(_id: { eq: "caseStudies" }) {
      slug {
        current
      }
    }
    article: sanityPage(_id: { eq: "blog" }) {
      slug {
        current
      }
    }
    resource: sanityPage(_id: { eq: "contentHub" }) {
      slug {
        current
      }
    }
  }
`;
